import {OrganizationDto} from '../../_dtos/user-dtos/organization-dto';
import {UserDto} from '../../_dtos/user-dtos/user-dto';
import {PromptService} from './prompt.service';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {LoginDto} from 'src/app/_dtos/auth-dtos/login-dto';
import {environment} from 'src/environments/environment';
import {JwtHelperService} from '@auth0/angular-jwt';
import {UserPermissionDto} from "../../_dtos/user-dtos/user-permission-dto";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private _baseUrl = environment.apiUrl + 'Auth/';
    private _backendClientTokenKey = 'backend-client-token';
    private _backendSelectedOrgKey = 'backend-selected-org';

    selectedOrganization: OrganizationDto;
    user: UserDto;
    userSub = new Subject<UserDto>();

    constructor(private http: HttpClient,
                private router: Router,
                private _prompt: PromptService,
                private _jwtHelper: JwtHelperService) {
        if (this.user) return;

        const token: string = localStorage.getItem(this._backendClientTokenKey);
        if (!token) return;
        if (_jwtHelper.isTokenExpired(token)) return;

        this.user = this._mapTokenToUser(token);
        if (!this.user) return;
        if (this.user?.organizations == null) return;

        if (this.user.organizations.length === 1) this.selectedOrganization = this.user.organizations[0];
        if (this.selectedOrganization != null) return;

        const selectedOrgName = localStorage.getItem(this._backendSelectedOrgKey);
        this.selectedOrganization = this.user.organizations.find(x => x.name === selectedOrgName);
    }

    login(dto: LoginDto) {
        this.http.post<any>(this._baseUrl + 'Login', dto)
            .subscribe(res => {
                localStorage.setItem('backend-client-token', res.token);
                this.user = this._mapTokenToUser(res.token);
                this.userSub.next(this.user);
            }, error => {
                this.userSub.error(error);
            });
    }

    logout() {
        localStorage.removeItem(this._backendClientTokenKey);
        this.userSub.next(null);
        this.selectedOrganization = null;
        this.router.navigate(['/']);
        this._prompt.message('Logged out');
    }

    selectOrganization(org: OrganizationDto) {
        this.selectedOrganization = org;
        localStorage.setItem(this._backendSelectedOrgKey, org.name);
    }

    getUserPermissions(): UserPermissionDto[] {
        const user = this.user;
        if (!user) return [];

        try {
            return user.permissions;
        } catch (error) {
            return [];
        }
    }

    private _mapTokenToUser(token: string): UserDto {
        if (!token) return null;

        try {
            const decodedToken = this._jwtHelper.decodeToken(token);
            const user: UserDto = {
                id: decodedToken['nameid'],
                username: decodedToken['unique_name'],
                role: decodedToken['role'],
                organizations: [],
                email: '',
                permissions: []
            };

            const orgsString = decodedToken['groupsid'];
            const orgs: OrganizationDto[] = JSON.parse(orgsString);
            const permissionsString = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'];
            const permissions: UserPermissionDto[] = JSON.parse(permissionsString);

            orgs.forEach(o => {
                user.organizations.push({id: o['Id'], name: o['Name'], organizationType: o['OrganizationType']});
            });

            user.permissions = permissions
                .map(x => ({
                    organizationId: x['OrganizationId'],
                    permission: x['Permission'],
                    expireDate: x['ExpireDate']
                }));

            return user;
        } catch (error) {
            return null;
        }
    }
}
